import Platform from "platform/Platform";
import {StoreState} from "kbd/core/redux/StoreState";
import {BIFinishOnBoardingType} from "kbd/enum/BIFinishOnBoardingType";
import {BIEventType} from "kbd/enum/BIEventType";
import {AppState} from "kbd/core/state/AppState";
import {FieldType} from "kbd/enum/FieldType";
import {BIErrorType} from "kbd/enum/BIErrorType";
import {PageType} from "kbd/enum/PageType";
import {FormikValues} from "formik";
import Utils from "platform/util/Utils";
import {PageNavigator} from "kbd/pages/PageNavigator";
import {getQuestionAnswerTitle} from "kbd/core/util/KycUtil";
import {QuestionKey} from "kbd/protocol/QuestionKey";
import {GetAfterLoginConfigurationResponse} from "kbd/protocol/account/GetAfterLoginConfigurationResponse";
import WebUtil from "platform/util/WebUtil";
import {RNBridge} from "kbd/core/integration/RNBridge";

export class BIUtil {

    private constructor() {
    }

    public static identify(response: GetAfterLoginConfigurationResponse) {
        Platform.bi().identify({
            UserId: response?.UserId,
            theme: Platform.reduxState<StoreState>().core.theme,
            realAccount: response?.IsRealAccount,
            device: BIUtil.Platform(),
        });
    }

    public static Platform = (): string => {
        return RNBridge.hasBridge() ? "Native Mobile" : WebUtil.isMobile() ? "Web Mobile" : "Web Desktop";
    }

    public static FinishOB(type: BIFinishOnBoardingType) {
        const sessionStartTime: number = AppState.instance().sessionStartTime;
        const {route} = Platform.reduxState().router;
        Platform.bi().track(BIEventType.OBOnBoardingEnded, {
            Type: type,
            ExitStep: route?.name,
            TotalOBTime: (new Date().getTime() - sessionStartTime) / 1000 / 60
        });
    }

    public static Continue(pageType: PageType, values: FormikValues, hasErrors: boolean, TimeOnPage: number) {
        const eventType: BIEventType = BIEventType.eventType(pageType);
        if (eventType) {
            const {addressExtended, acknowledgePoliticalExposedDescription, acknowledgeTINDescription, answers} = Platform.reduxState<StoreState>().kyc;
            const data: any = {};
            if (pageType === PageType.KycAddress) {
                data.AddressNotFoundClicked = addressExtended ? "yes" : "no";
            } else if (pageType === PageType.KycFinancialDetails) {
                data.IDFieldRequired = PageNavigator.hasField(FieldType.PassportNumber) ? "yes" : "no";
                data.PEPDetails = acknowledgePoliticalExposedDescription ? "yes" : "no";
                data.US = values[FieldType.UsCitizen] ? "yes" : "no";
                data.PEP = values[FieldType.PoliticalExposedPerson] ? "yes" : "no";
            } else if (pageType === PageType.KycTradingFamiliarity) {
                data.FamiliarWithTrading = Utils.isNotNull(values[FieldType.ListedSharesId]) || Utils.isNotNull(values[FieldType.ExchangeTradedFundsFamiliarityId])
                    || Utils.isNotNull(values[FieldType.FinancialDerivativesFamiliarityId]) || Utils.isNotNull(values[FieldType.WarrantsFamiliarityId]) ? "yes" : "no";
                data.FamiliarWithCFD = Utils.isNotNull(values[FieldType.CFDsFamiliarityId]) ? "yes" : "no";
            } else if (pageType === PageType.KycTradingExperience) {
                data.TradingExperience = getQuestionAnswerTitle(QuestionKey.YearsOfExperience, answers, values[FieldType.ExperienceTrading]);
                data.YearlyVolume = getQuestionAnswerTitle(QuestionKey.TradingVolumeInTheLast12MonthsType, answers, values[FieldType.TradingVolume]);
                data.TradingFrequency = getQuestionAnswerTitle(QuestionKey.TradingFrequencyType, answers, values[FieldType.TradingFrequency]);
                data.ProfessionalExperience = getQuestionAnswerTitle(QuestionKey.ProfessionalQualificationType, answers, values[FieldType.ExperienceInFinancialServices]);
            } else if (pageType === PageType.KycTradingExperienceIntentions) {
                data.TINInfo = acknowledgeTINDescription ? "yes" : "no";
            }
            Platform.bi().track(eventType, {
                Success: !hasErrors,
                TimeOnPage,
                ...data
            });
        }
    }

    public static Error(fieldType: FieldType | string, errorType: BIErrorType, error: string) {
        const {route} = Platform.reduxState().router;
        Platform.bi().track(BIEventType.OBError, {
            StepName: route?.name,
            FieldName: fieldType,
            ErrorType: errorType,
            ErrorDescription: error
        });
    }
}
