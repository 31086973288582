import {Engine} from "platform/engine/Engine";
import {
    DoGetTinDocUrlPayload,
    DoGetURLByTypePayload,
    DoRegisterActivity,
    DoRegisterActivityPayload,
    DoReportAgreeWithInAppropriatePayload,
    DoReportCNMVPayload,
    DoSaveKycFormPayload,
    DoSetSignaturePayload,
    FetchAnswersForQuestionsPayload,
    GoToTradingPlatform,
    SetAddressExtended,
    SetAnswersForQuestions,
    SetBirthCountries,
    SetClickFormSubmit,
    SetCnmvState,
    SetConfiguredCountries,
    SetDocumentsStandalone,
    SetFailedToInit,
    SetFinancialDetailsType,
    SetHasSignature,
    SetKycFinished,
    SetKycForm,
    SetKycStarted,
    SetKycSteps,
    SetKycStepsPayload,
    SetKycStepStatus,
    SetSignatureApproved,
    SetSubmitFormError,
    SetTinDocUrl,
    SetURLByType,
    SubmittingForm,
    UpdateKycSteps
} from "kbd/core/redux/kyc/KycReduxActions";
import {KycStepType} from "kbd/enum/KycStepType";
import {KycStep} from "kbd/core/redux/kyc/KycReduxState";
import {KycStepStatus} from "kbd/enum/KycStepStatus";
import Platform from "platform/Platform";
import Utils from "platform/util/Utils";
import {IPage, IPageField} from "kbd/pages/IPage";
import {PageNavigator} from "kbd/pages/PageNavigator";
import {NextPage, SetComplianceFlow, SetFormErrors, SetShowCallMeButton} from "kbd/core/redux/app/AppReduxActions";
import {PageType} from "kbd/enum/PageType";
import {StoreState} from "kbd/core/redux/StoreState";
import {Route} from "platform/redux/PlatformReduxState";
import {HideLoader, NavigateTo, SetException, SetLoader} from "platform/redux/core/CoreActions";
import {ComplianceFormInfo} from "kbd/protocol/ComplianceFormInfo";
import {TSMap} from "typescript-map";
import {FieldType} from "kbd/enum/FieldType";
import {Validation} from "kbd/core/util/Validation";
import {GetAllCountriesRequest} from "platform/protocol/common/GetAllCountriesRequest";
import {LangCode} from "platform/enum/LangCode";
import {GetAllCountriesResponse} from "platform/protocol/common/GetAllCountriesResponse";
import {LoadLanguagePayload} from "platform/redux/translation/TranslationActions";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {KycState} from "kbd/core/state/KycState";
import {ServiceType} from "kbd/enum/ServiceType";
import {FormikAdapter} from "kbd/core/util/FormikAdapter";
import {GetTINDcoumentURLRequest} from "kbd/protocol/request/GetTINDcoumentURLRequest";
import {GetTINDcoumentURLReponse} from "kbd/protocol/response/GetTINDcoumentURLReponse";
import {GetAnswersByQuestionKeysRequest} from "kbd/protocol/request/GetAnswersByQuestionKeysRequest";
import {GetAnswersByQuestionKeysResponse} from "kbd/protocol/response/GetAnswersByQuestionKeysResponse";
import {HttpReject} from "platform/network/http/Http";
import {GetComplianceFormResponse} from "kbd/protocol/response/GetComplianceFormResponse";
import {ExceptionType} from "kbd/enum/ExceptionType";
import {ActionType} from "kbd/core/common/action/ActionType";
import {XhrManager} from "kbd/core/engine/XhrManager";
import SubmitForm from "platform/network/form/SubmitForm";
import {UpdateComplianceFormRequest} from "kbd/protocol/request/UpdateComplianceFormRequest";
import {UpdateComplianceFormResponse} from "kbd/protocol/response/UpdateComplianceFormResponse";
import {ActionsInfoType} from "kbd/protocol/ActionsInfoType";
import {RegisterActivityRequest} from "kbd/protocol/request/RegisterActivityRequest";
import {ActivityTypeInfo} from "kbd/protocol/ActivityTypeInfo";
import {GetCNMVRequest} from "kbd/protocol/request/GetCNMVRequest";
import {GetCNMVResponse} from "kbd/protocol/response/GetCNMVResponse";
import {OperationResult} from "kbd/protocol/OperationResult";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {ErrorInfo} from "kbd/protocol/ErrorInfo";
import WebUtil from "platform/util/WebUtil";
import {UpdateInAppropriateRequest} from "kbd/protocol/request/UpdateInAppropriateRequest";
import {FormMethod} from "platform/enum/FormMethod";
import {SetSignatureRequest} from "kbd/protocol/request/SetSignatureRequest";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {UpdateInAppropriateResponse} from "kbd/protocol/response/UpdateInAppropriateResponse";
import GlobalIntegration from "platform/integration/GlobalIntegration";
import {
    IntegrationExecuteOperation,
    IntegrationOperation
} from "platform/integration/message/IntegrationExecuteOperation";
import {lastInProgressStep} from "kbd/core/util/KycUtil";
import {LoaderType} from "platform/enum/LoaderType";
import {ErrorCodeInfo} from "kbd/protocol/ErrorCodeInfo";
import {Routes} from "kbd/core/router/Routes";
import Translations from "platform/translation/Translations";
import {GetURLByTypeRequest} from "platform/protocol/common/GetURLByTypeRequest";
import {GetURLByTypeResponse} from "platform/protocol/common/GetURLByTypeReponse";
import {GetConfigurationRequest} from "kbd/protocol/request/GetConfigurationRequest";
import {GetConfigurationsResponse} from "kbd/protocol/response/GetConfigurationsResponse";
import {GetUrlType} from "platform/protocol/enum/GetUrlType";
import {ComplianceFlowType} from "kbd/enum/ComplianceFlowType";
import {UpdateAppropriateRequest} from "kbd/protocol/request/UpdateAppropriateRequest";
import {UpdateAppropriateResponse} from "kbd/protocol/response/UpdateAppropriateResponse";
import {SetSignatureResponse} from "kbd/protocol/response/SetSignatureResponse";
import {SetHasFtd, SkipDeposit} from "kbd/core/redux/deposit/DepositReduxActions";
import {Configuration} from "kbd/core/configuration/Configuration";
import {RNBridge} from "kbd/core/integration/RNBridge";
import {ReadyState} from "kbd/core/state/ReadyState";
import {BIUtil} from "kbd/core/util/BIUtil";
import {BIFinishOnBoardingType} from "kbd/enum/BIFinishOnBoardingType";
import {BIErrorType} from "kbd/enum/BIErrorType";
import {BIEventType} from "kbd/enum/BIEventType";
import {ConfigUtil} from "kbd/core/util/ConfigUtil";

const PredefinedAction: ActionsInfoType = ActionsInfoType.deserialize(WebUtil.urlParam("action"));
const UrlSkipWelcome: boolean = Utils.parseBoolean(WebUtil.urlParam("skipWelcome"));
const UrlSkipDeposit: boolean = Utils.parseBoolean(WebUtil.urlParam("skipDeposit"));
const PlatformType: string = WebUtil.urlParam("platform");
const WebviewPlatformType: string = WebUtil.urlParam("webview");

export default class KycEngine extends Engine {

    private static _instance: KycEngine;
    private _activities: RegisterActivityRequest[] = [];
    private _handler: any;
    private _goToThePlatform: boolean;

    public static instance(): KycEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        this._logger.debug(`Is Safari: ${WebUtil.isSafari()}. Is IOS: ${WebUtil.isIOS()}. Is web mob: ${WebUtil.isMobile()}. Is RN: ${WebUtil.inRNWebView()}. GET webview param: ${WebviewPlatformType}`);
        const request: GetConfigurationRequest = {LanguageCode: LangCode.EN};
        const answer: [HttpReject, GetConfigurationsResponse] = await Utils.to(XhrManager.sendToConfig(request, "GetConfigurations"));

        PageNavigator.setup(answer[1]?.ComplianceFlowType, answer[1]?.FinancialDetailsType);
        Platform.dispatch(SetComplianceFlow({complianceFlowType: answer[1]?.ComplianceFlowType}));
        Platform.dispatch(SetFinancialDetailsType({financialDetailsType: answer[1]?.FinancialDetailsType}));
        Platform.dispatch(SetShowCallMeButton({value: answer[1]?.ShowCallMeButtonOnboarding}));
        Routes.addActivator(PageType.KycWelcome, () => () => {
            const {kycStarted, hasSignature} = Platform.reduxState<StoreState>().kyc;
            return !kycStarted || !hasSignature;
        });
        const allowFormPage = () => () => {
            return !Platform.reduxState<StoreState>().kyc.kycFinished;
        };
        Routes.addActivator(PageType.KycPersonalInfo, allowFormPage);
        Routes.addActivator(PageType.KycAddress, allowFormPage);
        Routes.addActivator(PageType.KycFinancialDetails, allowFormPage);
        Routes.addActivator(PageType.KycTradingFamiliarity, allowFormPage);
        Routes.addActivator(PageType.KycTradingExperience, allowFormPage);
        Routes.addActivator(PageType.KycTradingExperienceKnowledge, allowFormPage);
        Routes.addActivator(PageType.KycTradingExperienceKnowledge2, allowFormPage);
        Routes.addActivator(PageType.KycTradingExperienceIntentions, allowFormPage);
        if (Utils.isNull(this._handler)) {
            this._handler = setInterval(this.doRegisterActivityInternal, 500);
        }
    }

    public onLoadLanguage = (payload: LoadLanguagePayload): void => {
        const {langCode} = Platform.reduxState().translation;
        if (langCode !== payload.langCode) {
            this.fetchCountries(payload.langCode).catch(() => {});
        }
    }

    public onAppReady = async () => {
        setInterval(() => {
            XhrManager.sendToConfig({}, "echo").catch(() => {});
        }, 5 * 60 * 1000);
        const {router, core} = Platform.reduxState<StoreState>();
        const routerPageType: PageType = router.route.name as PageType;
        this._logger.debug("Router page: " + routerPageType);
        const answer: [HttpReject, GetComplianceFormResponse] = await Utils.to(XhrManager.sendToForm({}, "GetComplianceForm"));
        if (answer[0]) {
            this._logger.debug("Failed fetch compliance form. Status: " + answer[0].status);
            Platform.dispatch(SetFailedToInit({failed: true}));
            Platform.router().navigate(PageType.KycWelcome);
        } else {
            if (answer[1]?.HasAlreadySubmittedSignature) {
                Platform.dispatch(SetHasSignature({}));
            }
            Platform.dispatch(SetFailedToInit({failed: false}));
            let addressExtended: boolean = false;
            const complianceForm: ComplianceFormInfo = answer[1].Form || {};
            this._logger.debug("Received compliance form. Action: " + answer[1].Action + " PredefinedAction " + PredefinedAction + " Show CNMV: " + answer[1].ShouldSeeCNMV);
            Platform.dispatch(SetKycForm({
                complianceForm,
                IsAppropriate: answer[1].IsAppropriate
            }));
            if (complianceForm.Address || complianceForm.City || complianceForm.PostalCode) {
                addressExtended = true;
                Platform.dispatch(SetAddressExtended({extended: true}));
            }
            if (answer[1].ShouldSeeCNMV) {
                Platform.dispatch(SetCnmvState({
                    state: {
                        retypeBlock: true
                    }
                }));
            }
            const documentsStandalone: boolean = PredefinedAction === ActionsInfoType.ShouldGoToDocument && answer[1].Action === ActionsInfoType.ShouldGoToWebProfit;
            if (documentsStandalone) {
                Platform.dispatch(SetDocumentsStandalone({}));
            }
            const shouldGoToFilledOut: boolean = answer[1].Action === ActionsInfoType.ShouldGoToFilledOut;
            const shouldGoToInappropriate: boolean = answer[1].Action === ActionsInfoType.ShouldGoToInappropriate;
            const shouldGoToAppropriate: boolean = answer[1].Action === ActionsInfoType.ShouldGoToAppropriate;
            const shouldGoToCNMV: boolean = answer[1].Action === ActionsInfoType.ShouldGoToCNMV;
            const shouldGoToDocuments: boolean = answer[1].Action === ActionsInfoType.ShouldGoToDocument;
            const shouldProceedWithForm: boolean = answer[1].Action === ActionsInfoType.ShouldStayAtComplianceOnBoarding;
            const shouldGoToSignature: boolean = answer[1].Action === ActionsInfoType.ShouldGoElectronicSignature;
            const shouldGoToTradingProfile: boolean = answer[1].Action === ActionsInfoType.ShouldGoToProductGovernanceQuestions;
            const shouldGoToThankYou: boolean = answer[1].Action === ActionsInfoType.ShouldGoToThankYouMessage;
            const steps: TSMap<KycStepType, KycStep> = new TSMap();
            let page: IPage;
            const pages: IPage[] = PageNavigator.pages();
            const lastFormPagePassed: boolean = this.isPagePassed(PageNavigator.lastKycFormPage(), complianceForm, false, addressExtended);
            for (let i = 0; i < pages.length; i++) {
                page = pages[i];
                if (PageNavigator.isKycFormPage(page.type)) {
                    const step: KycStep = steps.get(page.kycStep) || {type: page.kycStep};
                    steps.set(page.kycStep, step);
                    if (this.isPagePassed(page, complianceForm, lastFormPagePassed, addressExtended)) {
                        step.status = KycStepStatus.Completed;
                    } else {
                        if (!shouldGoToFilledOut) {
                            step.status = KycStepStatus.InProgress;
                        }
                        this._logger.debug("Detected in progress step: " + step.type);
                        break;
                    }
                } else if (page.type === PageType.DepositTopUp) {
                    let topUpStatus: KycStepStatus = KycStepStatus.InProgress;
                    if (shouldGoToCNMV || shouldGoToDocuments || shouldProceedWithForm || shouldGoToSignature || shouldGoToFilledOut || shouldGoToInappropriate || shouldGoToAppropriate || shouldGoToTradingProfile || shouldGoToThankYou) {
                        const {hasFTD} = Platform.reduxState<StoreState>().deposit;
                        if (Utils.isNotNull(hasFTD) && !hasFTD) {
                            topUpStatus = KycStepStatus.Skipped;
                        } else {
                            topUpStatus = KycStepStatus.Completed;
                        }
                    }
                    steps.set(KycStepType.TopUp, {type: KycStepType.TopUp, status: topUpStatus});
                }
            }
            const lastFormStep: KycStep = PageNavigator.lastKycFormStep(steps);
            const formCompleted: boolean = lastFormStep.status === KycStepStatus.Completed || shouldGoToFilledOut;
            if (formCompleted) {
                Platform.dispatch(SetKycFinished({}));
            }
            const shouldGoToDeposit: boolean = answer[1].Action === ActionsInfoType.ShouldGoToDeposit;
            const isPageBeforeDeposit: boolean = PageNavigator.isPageBeforeDeposit(page.type);
            const isFormInProgressBeforeDeposit: boolean = shouldGoToDeposit && lastFormStep.status === KycStepStatus.InProgress && isPageBeforeDeposit;
            this._logger.debug("Last form step: " + JSON.stringify(lastFormStep) + " Form completed: " + formCompleted + " With page before deposit: " + isPageBeforeDeposit);
            if (!isFormInProgressBeforeDeposit && shouldGoToDeposit) {
                Platform.dispatch(SetKycStarted({}));
                if (!formCompleted) {
                    lastFormStep.status = KycStepStatus.NotPassed;
                }
            }
            const {complianceFlowType} = Platform.reduxState<StoreState>().kbdApp;
            if (formCompleted && shouldGoToSignature && complianceFlowType !== ComplianceFlowType.CNMVOnBoarding) {
                steps.set(KycStepType.TradingProfile, {type: KycStepType.TradingProfile, status: KycStepStatus.Completed});
                steps.set(KycStepType.IdentityVerification, {type: KycStepType.IdentityVerification, status: KycStepStatus.Completed});
            }
            if (shouldGoToThankYou) {
                steps.set(KycStepType.TradingProfile, {type: KycStepType.TradingProfile, status: KycStepStatus.Completed});
                steps.set(KycStepType.IdentityVerification, {type: KycStepType.IdentityVerification, status: KycStepStatus.Completed});
                steps.set(KycStepType.StartTrading, {type: KycStepType.StartTrading, status: KycStepStatus.InProgress});
            }
            Platform.dispatch(SetKycSteps({
                steps: steps.values(),
                page
            }));
            const actionTo: ActionsInfoType = PredefinedAction || answer[1].Action;
            if (actionTo === ActionsInfoType.ShouldStayAtComplianceOnBoarding && formCompleted && !answer[1].HasAlreadySubmittedSignature) {
                Platform.dispatch(NavigateTo({route: PageType.KycWelcome}));
            } else {
                if (ActionsInfoType.aggressive(actionTo) || (!isFormInProgressBeforeDeposit && (formCompleted || (!formCompleted && shouldGoToDeposit)))) {
                    this.executeFormAction(actionTo);
                } else {
                    this._logger.debug("Skip execute action: " + actionTo);
                }
            }
            ReadyState.hasComplianceForm = true;
        }
    }

    private isPagePassed(page: IPage, complianceForm: ComplianceFormInfo, lastFormPagePassed: boolean, addressExtended: boolean): boolean {
        if (Utils.isArrayNotEmpty(page.fields)) {
            const fields: IPageField[] = page.fields;
            for (let i = 0; i < fields.length; i++) {
                const field: IPageField = fields[i];
                if ((field.type === FieldType.UsCitizen && complianceForm.IsUSACitizen) || (field.type === FieldType.PoliticalExposedPerson && complianceForm.IsPoliticalPerson)) {
                    return false;
                }
                if (!field.disabled && !field.optional && Validation.isFieldVisible(field.type, complianceForm) && !Validation.validateFormField(field.type, complianceForm, lastFormPagePassed, addressExtended)) {
                    return false;
                }
            }
        }
        return true;
    }

    private fetchCountries = async (langCode: LangCode) => {
        this._logger.debug("Fetch countries for lang code: " + langCode);
        const request: GetAllCountriesRequest = {LanguageCode: langCode};
        const answer: [HttpReject, GetAllCountriesResponse] = await Utils.to(XhrManager.sendToConfig(request, "GetAllCountries"));
        const answerBC: [HttpReject, GetAllCountriesResponse] = await Utils.to(XhrManager.sendToConfig(request, "GetBirthCountries"));
        if (answer[0]) {
            this._logger.debug("Failed fetch countries list. Status: " + answer[0].status);
        } else {
            const response: GetAllCountriesResponse = answer[1];
            if (Utils.isArrayNotEmpty(response.Countries)) {
                this._logger.debug("Received countries list. Size: " + response.Countries.length);
                const countries: TSMap<number, CountryInfo> = new TSMap();
                const namePerCountry: TSMap<string, CountryInfo> = new TSMap();
                const kycState: KycState = Platform.state(ServiceType.Kyc);
                kycState.clearCountries();
                response.Countries.sort((ci1: CountryInfo, ci2: CountryInfo) => Utils.compareString(ci1.LocalizedName, ci2.LocalizedName)).forEach((countryInfo: CountryInfo) => {
                    countryInfo.LocalizedName = (countryInfo.LocalizedName || "").trim();
                    countries.set(countryInfo.Id, countryInfo);
                    namePerCountry.set(countryInfo.LocalizedName, countryInfo);
                    kycState.addCountry(countryInfo);
                });
                Platform.dispatch(SetConfiguredCountries({
                    countries, namePerCountry
                }));
            } else {
                this._logger.debug("Countries list empty");
            }
        }
        if (answerBC[0]) {
            this._logger.debug("Failed fetch countries list. Status: " + answerBC[0].status);
        } else {
            const response: GetAllCountriesResponse = answerBC[1];
            if (Utils.isArrayNotEmpty(response.Countries)) {
                this._logger.debug("Birth countries list empty" + response.Countries.length);
                const namePerCountry: TSMap<string, CountryInfo> = new TSMap();
                response.Countries.sort((ci1: CountryInfo, ci2: CountryInfo) => Utils.compareString(ci1.LocalizedName, ci2.LocalizedName)).forEach((countryInfo: CountryInfo) => {
                    namePerCountry.set(countryInfo.LocalizedName, countryInfo);
                });
                Platform.dispatch(SetBirthCountries({
                    countries: namePerCountry
                }));
            } else {
                this._logger.debug("Birth countries list empty");
            }
        }
    }

    private fetchCNMV = async () => {
        const langCode: LangCode = LanguageUtil.languageCode();
        const request: GetCNMVRequest = {LanguageCode: langCode};
        const answer: [HttpReject, GetCNMVResponse] = await Utils.to(XhrManager.sendToForm(request, "GetCNMV"));
        if (answer[0]) {
            this._logger.debug("Failed fetch CNMV. Status: " + answer[0].status);
        } else {
            const {CNMVText} = answer[1];
            this._logger.debug("Received CNMV text: " + CNMVText);
            Platform.dispatch(SetCnmvState({
                state: {
                    retypeText: CNMVText
                }
            }));
        }
    }

    public doGetTinDocUrl = async (payload: DoGetTinDocUrlPayload) => {
        this._logger.debug("Fetching TIN doc url for country: " + payload.countryName);
        const {namePerCountry} = Platform.reduxState<StoreState>().kyc;
        const ci: CountryInfo = namePerCountry.get((payload.countryName || "").trim());
        if (ci) {
            const request: GetTINDcoumentURLRequest = {CountryId: ci.Id};
            const answer: [HttpReject, GetTINDcoumentURLReponse] = await Utils.to(XhrManager.sendToConfig(request, "GetTINDocumentURL"));
            if (answer[0]) {
                this._logger.debug("Failed fetch TIN doc url. Status: " + answer[0].status);
                Platform.dispatch(SetTinDocUrl({url: null}));
            } else {
                const response: GetTINDcoumentURLReponse = answer[1];
                this._logger.debug("Received TIN doc url: " + response.URL);
                Platform.dispatch(SetTinDocUrl({url: response.URL}));
            }
        } else {
            this._logger.info("Country Info not found for name '" + payload.countryName + "' during fetch TIN doc url");
        }
    }

    public doFetchAnswersForQuestions = async (payload: FetchAnswersForQuestionsPayload) => {
        const kycState: KycState = Platform.state(ServiceType.Kyc);
        if (!kycState.hasAnswersForPage(payload.pageType)) {
            const page: IPage = PageNavigator.page(payload.pageType);
            if (Utils.isArrayNotEmpty(page.questionKeys)) {
                this._logger.debug("Fetch answers for page: " + payload.pageType);
                const langCode: LangCode = LanguageUtil.languageCode();
                const request: GetAnswersByQuestionKeysRequest = {LanguageCode: langCode, QuestionKeys: page.questionKeys};
                const answer: [HttpReject, GetAnswersByQuestionKeysResponse] = await Utils.to(XhrManager.sendToConfig(request, "GetAnswersByQuestionKeys"));
                if (answer[0]) {
                    this._logger.debug("Failed fetch answers for questions. Status: " + answer[0].status);
                } else {
                    const response: GetAnswersByQuestionKeysResponse = answer[1];
                    this._logger.debug("Received answers for questions for page: " + payload.pageType);
                    kycState.setHasAnswersForPage(payload.pageType);
                    Platform.dispatch(SetAnswersForQuestions({answers: response.QuestionKeyWithAnswers}));
                }
            }
        }
    }

    public doGetURLByType = async (payload: DoGetURLByTypePayload) => {
        if (Utils.isNotNull(payload.urlType)) {
            const langCode: LangCode = LanguageUtil.languageCode();
            const request: GetURLByTypeRequest = {LanguageCode: langCode, URLType: payload.urlType};
            const answer: [HttpReject, GetURLByTypeResponse] = await Utils.to(XhrManager.sendToLoginSecure(request, "GetURLByType"));
            if (answer[0]) {
                this._logger.debug("Failed fetch url by type " + payload.urlType + ". Status: " + answer[0].status);
                XhrManager.notifyRejectReason(answer);
            } else {
                const response: GetURLByTypeResponse = answer[1];
                this._logger.debug("Received url for type " + payload.urlType + ". URL: " + response.URL);
                const {theme} = Platform.reduxState<StoreState>().core;
                const parameters: { [key: string]: string } = {};
                // TODO Need to drop 'themeType' for old deposit
                parameters.themeType = theme;
                parameters.theme = theme;
                if (payload.urlType === GetUrlType.Deposit && WebviewPlatformType) {
                    parameters.webview = WebviewPlatformType;
                }
                const targetUrl: string = ConfigUtil.UseAddressBarDomain(response.URL);
                const depositRedirect: boolean = payload.urlType === GetUrlType.Deposit
                    && Platform.config<Configuration>().deposit?.redirect && WebUtil.isSafari();
                if (Utils.isNotNull(payload.actionType) || depositRedirect) {
                    if (payload.actionType === ActionType.Navigate || depositRedirect) {
                        parameters.token = response.Token;
                        parameters.language = langCode;
                        parameters.Cult = langCode;
                        if (response.Method === FormMethod.POST) {
                            SubmitForm.post(targetUrl, parameters);
                        } else {
                            Platform.environment().redirect(WebUtil.addGetParams(targetUrl, parameters));
                        }
                    } else {
                        this._logger.debug("Can't perform action after fetch URL by type: " + payload.actionType);
                    }
                } else {
                    Platform.dispatch(SetURLByType({
                        urlType: payload.urlType,
                        url: WebUtil.addGetParams(targetUrl, parameters)
                    }));
                }
            }
        }
    }

    public setKycSteps = (payload: SetKycStepsPayload): void => {
        const isKycFormPage: boolean = PageNavigator.isKycFormPage(payload.page.type);
        if (isKycFormPage) {
            if (UrlSkipWelcome) {
                Platform.dispatch(NavigateTo({route: payload.page.type}));
            } else {
                Platform.dispatch(NavigateTo({route: PageType.KycWelcome}));
            }
        } else {
            this.navigateToLastStep(payload.steps, payload.page.type);
        }
    }

    public doStarKyc = (): void => {
        const {steps, lastFormPage} = Platform.reduxState<StoreState>().kyc;
        this.navigateToLastStep(steps.values(), lastFormPage);
    }

    private navigateToLastStep = (steps: KycStep[], pageType: PageType): void => {
        if (Utils.isArrayNotEmpty(steps)) {
            const kycLastStep: KycStep = steps[steps.length - 1];
            if (Utils.isNotNull(kycLastStep)) {
                const kycStepType: KycStepType = KycStepType[kycLastStep.type];
                if (Utils.isNotNull(kycStepType)) {
                    this._logger.debug("Navigate to in progress Kyc step: " + kycStepType + " Page type: " + pageType);
                    Platform.dispatch(NavigateTo({route: pageType}));
                }
            }
        }
    }

    public onChangeRoute = (payload): void => {
        const route: Route = payload.route;
        const pageType: PageType = route.name as PageType;
        Platform.dispatch(SetSubmitFormError({error: false}));
        Platform.dispatch(SetClickFormSubmit({
            pageType: route.name as PageType,
            submit: false
        }));
        Platform.dispatch(SetException({
            exceptionType: ExceptionType.Form,
            error: null
        }));
        const activityTypeInfo: ActivityTypeInfo = ActivityTypeInfo.pageOpenActivityType(pageType);
        if (Utils.isNotNull(activityTypeInfo) && Routes.canActivate(pageType) && !PageNavigator.isDocumentPage(pageType)) {
            Platform.dispatch(DoRegisterActivity({activityType: activityTypeInfo}));
        }
    }

    private fetchHasFtd = async (): Promise<boolean> => {
        let hasFTD: boolean = Platform.reduxState<StoreState>().deposit.hasFTD;
        if (!hasFTD) {
            const request: GetConfigurationRequest = {LanguageCode: LangCode.EN};
            const answer: [HttpReject, GetConfigurationsResponse] = await Utils.to(XhrManager.sendToConfig(request, "GetConfigurations"));
            if (answer[1]?.HasFTD) {
                hasFTD = true;
                Platform.dispatch(SetHasFtd({hasFtd: true}));
            }
        }
        return hasFTD;
    }

    public doSkipDeposit = async () => {
        this._logger.debug("Skip Deposit step!!!");
        const hasFTD: boolean = await this.fetchHasFtd();
        Platform.dispatch(SetKycStepStatus({
            stepType: KycStepType.TopUp,
            status: hasFTD ? KycStepStatus.Completed : KycStepStatus.Skipped
        }));
        this.navigateAfterTopUp();
    }

    public onFinishTopUp = async () => {
        this._logger.debug("Handle finish deposit top up.");
        await this.fetchHasFtd();
        Platform.dispatch(SetKycStepStatus({
            stepType: KycStepType.TopUp,
            status: KycStepStatus.Completed
        }));
        this.navigateAfterTopUp();
    }

    private navigateAfterTopUp = (): void => {
        const {steps, lastFormPage, kycFinished} = Platform.reduxState<StoreState>().kyc;
        if (kycFinished) {
            let nextStepType: KycStepType = PageNavigator.nextKycStep(KycStepType.TopUp);
            this._logger.debug("Configured step after top up: " + nextStepType);
            if (PageNavigator.isKycFormStep(nextStepType)) {
                const lastStep: KycStepType = lastInProgressStep();
                const page: IPage = PageNavigator.pageByStep(lastStep);
                if (page) {
                    this._logger.debug("Navigate to page: " + page.type);
                    Platform.dispatch(NavigateTo({route: page.type}));
                } else {
                    const lastKycFormPage: IPage = PageNavigator.lastKycFormPage();
                    const stepAfterForm: KycStepType = PageNavigator.nextKycStep(lastKycFormPage.kycStep);
                    this._logger.info("Can't find last in progress page after TopUp. Last Form page: " + lastKycFormPage.type + " Step after form: " + stepAfterForm);
                    const {retypeBlock} = Platform.reduxState<StoreState>().kyc.cnvmState;
                    if (stepAfterForm === KycStepType.IdentityVerification && retypeBlock) {
                        this._logger.debug("Intercept to show CNMV page after skip/finish deposit");
                        this.executeFormAction(ActionsInfoType.ShouldGoToDocument);
                    } else {
                        Platform.dispatch(SetKycStepStatus({
                            stepType: stepAfterForm,
                            status: KycStepStatus.InProgress
                        }));
                        Platform.dispatch(NextPage({route: lastKycFormPage.type}));
                    }
                }
            } else {
                let step: KycStep = steps.get(nextStepType);
                while (Utils.isNotNull(step)) {
                    if (step.status === KycStepStatus.NotPassed || step.status === KycStepStatus.InProgress) {
                        break;
                    }
                    const possibleNextStep: KycStepType = PageNavigator.nextKycStep(nextStepType);
                    if (Utils.isNotNull(possibleNextStep)) {
                        nextStepType = possibleNextStep;
                        this._logger.debug("Fetched next step: " + nextStepType);
                        step = steps.get(nextStepType);
                    } else {
                        break;
                    }
                }
                const {retypeBlock} = Platform.reduxState<StoreState>().kyc.cnvmState;
                if ((nextStepType === KycStepType.IdentityVerification || nextStepType === KycStepType.TradingProfile) && retypeBlock) {
                    this._logger.debug("Intercept to show CNMV page after skip/finish deposit");
                    this.executeFormAction(nextStepType === KycStepType.TradingProfile ? ActionsInfoType.ShouldGoToProductGovernanceQuestions : ActionsInfoType.ShouldGoToDocument);
                } else {
                    const nextPage: IPage = PageNavigator.pageByStep(nextStepType);
                    this._logger.debug("Going to next step: " + nextStepType + " Page: " + nextPage.type);
                    Platform.dispatch(SetKycStepStatus({
                        stepType: nextStepType,
                        status: KycStepStatus.InProgress
                    }));
                    Platform.dispatch(NavigateTo({route: nextPage.type}));
                }
            }
        } else {
            const isPageBeforeDeposit: boolean = PageNavigator.isPageBeforeDeposit(lastFormPage);
            if (isPageBeforeDeposit) {
                let nextStepType: KycStepType;
                const lastFormStep: KycStep = PageNavigator.lastKycFormStep(steps);
                if (lastFormStep.status === KycStepStatus.Completed) {
                    nextStepType = PageNavigator.nextFormStep(lastFormStep.type);
                } else {
                    nextStepType = lastFormStep.type;
                }
                const nextPage: IPage = PageNavigator.pageByStep(nextStepType);
                Platform.dispatch(NavigateTo({route: nextPage.type}));
                Platform.dispatch(SetKycStepStatus({
                    stepType: nextStepType,
                    status: KycStepStatus.InProgress
                }));
            } else {
                this._logger.debug("Form not finished. Going to last form page: " + lastFormPage);
                Platform.dispatch(SetKycStepStatus({
                    stepType: PageNavigator.page(lastFormPage).kycStep,
                    status: KycStepStatus.InProgress
                }));
                this.navigateToLastStep(steps.values(), lastFormPage);
            }
        }
    }

    public doSkipIdentityVerification = (): void => {
        this._logger.debug("Skip Identity verification step!!!");
        Platform.dispatch(SetKycStepStatus({
            stepType: KycStepType.IdentityVerification,
            status: KycStepStatus.Skipped
        }));
        const nextStep: KycStepType = PageNavigator.nextKycStep(KycStepType.IdentityVerification);
        const nextPage: IPage = PageNavigator.pageByStep(nextStep);
        Platform.dispatch(NavigateTo({route: nextPage.type}));
        Platform.dispatch(SetKycStepStatus({
            stepType: nextStep,
            status: KycStepStatus.InProgress
        }));
    }

    private executeFormAction = (action: ActionsInfoType, callback?: () => void): void => {
        this._logger.debug("Try to execute server action: " + action);
        if (action === ActionsInfoType.ShouldGoToCNMV) {
            const {retypeBlock} = Platform.reduxState<StoreState>().kyc.cnvmState;
            if (retypeBlock) {
                this.fetchCNMV().catch(() => {});
                Platform.dispatch(NavigateTo({route: PageType.KycCnmv}));
            }
        } else if (action === ActionsInfoType.ShouldGoToProductGovernanceQuestions) {
            const page: IPage = PageNavigator.pageByStep(KycStepType.TradingProfile);
            if (page) {
                const {retypeBlock} = Platform.reduxState<StoreState>().kyc.cnvmState;
                if (retypeBlock) {
                    this.fetchCNMV().catch(() => {});
                    Platform.dispatch(NavigateTo({route: PageType.KycCnmv}));
                } else {
                    Platform.dispatch(SetKycStepStatus({
                        stepType: KycStepType.TradingProfile,
                        status: KycStepStatus.InProgress
                    }));
                    Platform.dispatch(NavigateTo({route: page.type}));
                }
            } else {
                this._logger.debug("Can't find trading profile page");
            }
        } else if (action === ActionsInfoType.ShouldGoToFinancialDetailsInternational) {
            Platform.dispatch(SetKycStepStatus({
                stepType: KycStepType.FinancialDetailsInternational,
                status: KycStepStatus.InProgress
            }));
            Platform.dispatch(NavigateTo({route: PageType.KycFinancialDetailsInternational}));
        } else if (action === ActionsInfoType.ShouldGoToDocument) {
            const page: IPage = PageNavigator.pageByStep(KycStepType.IdentityVerification);
            if (page) {
                const {retypeBlock} = Platform.reduxState<StoreState>().kyc.cnvmState;
                if (retypeBlock) {
                    this.fetchCNMV().catch(() => {});
                    Platform.dispatch(NavigateTo({route: PageType.KycCnmv}));
                } else {
                    Platform.dispatch(SetKycStepStatus({
                        stepType: KycStepType.TradingProfile,
                        status: KycStepStatus.Completed
                    }));
                    Platform.dispatch(SetKycStepStatus({
                        stepType: KycStepType.IdentityVerification,
                        status: KycStepStatus.InProgress
                    }));
                    Platform.dispatch(NavigateTo({route: page.type}));
                }
            } else {
                this._logger.debug("Can't find first documents page");
            }
        } else if (action === ActionsInfoType.ShouldGoToDeposit) {
            if (UrlSkipDeposit) {
                const {kycFinished} = Platform.reduxState<StoreState>().kyc;
                if (kycFinished) {
                    Platform.dispatch(SkipDeposit({}));
                }
            } else {
                Platform.dispatch(SetKycStepStatus({
                    stepType: KycStepType.TopUp,
                    status: KycStepStatus.InProgress
                }));
                Platform.dispatch(NavigateTo({route: PageType.DepositTopUp}));
            }
        } else if (action === ActionsInfoType.ShouldGoElectronicSignature) {
            const {complianceFlowType} = Platform.reduxState<StoreState>().kbdApp;
            if (complianceFlowType === ComplianceFlowType.CNMVOnBoarding) {
                Platform.dispatch(NavigateTo({route: PageType.KycSignature}));
            } else {
                Platform.dispatch(SetKycStepStatus({
                    stepType: KycStepType.StartTrading,
                    status: KycStepStatus.InProgress
                }));
                Platform.dispatch(NavigateTo({route: PageType.KycCongratulation}));
            }
        } else if (action === ActionsInfoType.ShouldGoToInappropriate || action === ActionsInfoType.ShouldGoToInappropriateWithPrint) {
            Platform.dispatch(NavigateTo({
                route: PageType.KycInAppropriate,
                params: {
                    ServerAction: action
                }
            }));
        } else if (action === ActionsInfoType.ShouldGoToAppropriate) {
            Platform.dispatch(NavigateTo({
                route: PageType.KycAppropriate,
                params: {
                    ServerAction: action
                }
            }));
        } else if (action === ActionsInfoType.ShouldGoToFilledOut) {
            Routes.blockAllExcept(PageType.KycFilledOut);
            Platform.dispatch(NavigateTo({route: PageType.KycFilledOut}));
        } else if (action === ActionsInfoType.ShouldGoToWebProfit) {
            this.goToTradingPlatform().catch(() => {});
        } else if (action === ActionsInfoType.ShouldGoToThankYouMessage) {
            Platform.dispatch(NavigateTo({route: PageType.KycCongratulation, params: {
                    IsThankYouAction: true
                }}));
        } else if (callback) {
            callback();
        }
    }

    public doReportCNMV = async (payload: DoReportCNMVPayload) => {
        const {complianceFlowType} = Platform.reduxState<StoreState>().kbdApp;
        const {retypeBlock} = Platform.reduxState<StoreState>().kyc.cnvmState;
        if (retypeBlock) {
            const langCode: LangCode = LanguageUtil.languageCode();
            this._logger.debug("Do report CNMV: " + payload.cnmv);
            const request: UpdateComplianceFormRequest = {
                LanguageCode: langCode,
                Form: {CNMVText: payload.cnmv}
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, UpdateComplianceFormResponse] = await Utils.to(XhrManager.sendToForm(request, "UpdateComplianceForm"));
            Platform.dispatch(HideLoader({}));
            if (answer[0]) {
                this._logger.debug("Failed report CNMV. Status: " + answer[0].status);
                XhrManager.notifyRejectReason(answer);
            } else {
                const {Success, Action, Errors} = answer[1];
                if (Success) {
                    Routes.addActivator(PageType.KycCnmv, () => () => {
                        return false;
                    });
                    this._logger.debug("CNMV reported. Server action: " + Action);
                    Platform.dispatch(SetCnmvState({
                        state: {
                            retypeBlock: false
                        }
                    }));
                    this.executeFormAction(ActionsInfoType.ShouldGoToProductGovernanceQuestions);
                } else {
                    this._logger.debug("Unsuccessful CNMV report.");
                    let retypeErrorText: string = Errors && Errors[0] ? Errors[0].Message : null;
                    if (!retypeErrorText) {
                        retypeErrorText = Translations.text(TranslationKey.kycInAppropriateRetypeError);
                    }
                    Platform.dispatch(SetCnmvState({
                        state: {
                            retypeErrorText
                        }
                    }));
                }
            }
        } else {
            this._logger.debug("Retype block not configured. Skip report CNMV");
        }
    }

    public doSaveKycForm = async (payload: DoSaveKycFormPayload) => {
        if (Utils.isNotNull(payload.stepType)) {
            const langCode: LangCode = LanguageUtil.languageCode();
            const finalForm: ComplianceFormInfo = this.adjustForm(payload.form);
            const isLastFormPage: boolean = PageNavigator.isLastKycFormPage(payload.pageType);
            Platform.dispatch(SubmittingForm({submitting: true}));
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            this._logger.debug("Submitting form for step: " + payload.stepType + " Page type: " + payload.pageType + " Is last form page: " + isLastFormPage);
            const activityTypeInfo: ActivityTypeInfo = ActivityTypeInfo.pageCloseActivityType(payload.pageType);
            const request: UpdateComplianceFormRequest = {
                LanguageCode: langCode,
                Form: finalForm,
                IsKYCFilled: isLastFormPage,
                StepInfo: activityTypeInfo
            };
            const answer: [HttpReject, UpdateComplianceFormResponse] = await Utils.to(XhrManager.sendToForm(request, "UpdateComplianceForm"));
            Platform.dispatch(SubmittingForm({submitting: false}));
            Platform.dispatch(SetFormErrors({errors: null}));
            Platform.dispatch(HideLoader({}));
            if (answer[0]) {
                this._logger.debug("Failed update compliance form. Status: " + answer[0].status);
                XhrManager.notifyRejectReason(answer);
            } else {
                const {Success, Action, Errors, ShouldSeeCNMV, IsAppropriate} = answer[1];
                if (Success) {
                    if (Utils.isNotNull(activityTypeInfo)) {
                        Platform.dispatch(DoRegisterActivity({activityType: activityTypeInfo}));
                    }
                    if (isLastFormPage) {
                        Platform.dispatch(DoRegisterActivity({activityType: ActivityTypeInfo.KYCSubmitted}));
                    }
                    Platform.dispatch(SetKycForm({
                        complianceForm: finalForm,
                        IsAppropriate
                    }));
                    if (ShouldSeeCNMV) {
                        Platform.dispatch(SetCnmvState({
                            state: {
                                retypeBlock: true
                            }
                        }));
                    }
                    const step: KycStep = Platform.reduxState<StoreState>().kyc.steps.get(payload.stepType);
                    const nextPage: IPage = PageNavigator.next(payload.pageType);
                    const status: KycStepStatus = nextPage.kycStep === payload.stepType && step.status !== KycStepStatus.Completed ? KycStepStatus.InProgress : KycStepStatus.Completed;
                    this._logger.debug("Handle submit response for step: " + payload.stepType + " Page type: " + payload.pageType + " Step status " + KycStepStatus[status]);
                    Platform.dispatch(SetKycStepStatus({
                        stepType: payload.stepType, status
                    }));
                    const callback = () => {
                        Platform.dispatch(NextPage({
                            route: payload.pageType
                        }));
                        if (status === KycStepStatus.Completed) {
                            const nextStep: KycStepType = PageNavigator.nextKycStep(payload.stepType);
                            if (Utils.isNotNull(nextStep)) {
                                this._logger.debug("Step: " + payload.stepType + " Completed!!! Going to next step: " + nextStep);
                                const nextStepState: KycStep = Platform.reduxState<StoreState>().kyc.steps.get(nextStep);
                                if (Utils.isNull(nextStepState) || (nextStepState.status !== KycStepStatus.Completed && nextStepState.status !== KycStepStatus.Skipped)) {
                                    Platform.dispatch(SetKycStepStatus({
                                        stepType: nextStep,
                                        status:  KycStepStatus.InProgress
                                    }));
                                }
                            } else {
                                this._logger.debug("KYC Finished!");
                            }
                        }
                    };
                    const formAlreadySubmitted: boolean = this.kycAlreadySubmitted(Errors);
                    if (formAlreadySubmitted) {
                        const steps: TSMap<KycStepType, KycStep> = new TSMap();
                        const stepTypes: KycStepType[] = PageNavigator.kycSteps();
                        for (let i = 0; i < stepTypes.length; i++) {
                            const stepType: KycStepType = stepTypes[i];
                            if (PageNavigator.isKycFormStep(stepType)) {
                                steps.set(stepType, {type: stepType, status: KycStepStatus.Completed});
                            }
                        }
                        if (Action === ActionsInfoType.ShouldGoElectronicSignature) {
                            steps.set(KycStepType.IdentityVerification, {type: KycStepType.IdentityVerification, status: KycStepStatus.Completed});
                        }
                        if (Action === ActionsInfoType.ShouldGoToDocument || Action === ActionsInfoType.ShouldGoElectronicSignature || PageNavigator.isDepositInTheMiddleOfKycForm()) {
                            const {hasFTD} = Platform.reduxState<StoreState>().deposit;
                            steps.set(KycStepType.TopUp, {type: KycStepType.TopUp, status: hasFTD ? KycStepStatus.Completed : KycStepStatus.Skipped});
                        }
                        Platform.dispatch(UpdateKycSteps({steps}));
                    }
                    if (isLastFormPage || formAlreadySubmitted) {
                        Platform.dispatch(SetKycFinished({}));
                        this.executeFormAction(Action, callback);
                    } else {
                        if (ActionsInfoType.aggressive(Action)) {
                            this.executeFormAction(Action);
                        } else {
                            callback();
                        }
                    }
                } else {
                    this.processFormErrors(payload.pageType, Errors);
                }
            }
        } else {
            this._logger.warn("Can't store form for undefined step");
        }
    }

    private kycAlreadySubmitted = (Errors: ErrorInfo[]): boolean => {
        if (Errors) {
            for (let i = 0; i < Errors.length; i++) {
                const error: ErrorInfo = Errors[i];
                if (error && error.Code === ErrorCodeInfo.InvalidFormSubmission) {
                    return true;
                }
            }
        }
        return false;
    }

    private adjustForm = (form: any): ComplianceFormInfo => {
        const complianceForm: ComplianceFormInfo = Platform.reduxState<StoreState>().kyc.complianceForm;
        const toAdjust: any = {...form};
        Object.keys(toAdjust).forEach((key: FieldType) => {
            toAdjust[key] = FormikAdapter.fromFormik(key, toAdjust[key], toAdjust);
        });
        const industry: number = toAdjust[FieldType.Industry];
        if (industry !== 30) {
            delete toAdjust[FieldType.IndustryDescription];
        }
        delete toAdjust[`${FieldType.ListedSharesId}_checkbox`];
        delete toAdjust[`${FieldType.ExchangeTradedFundsFamiliarityId}_checkbox`];
        delete toAdjust[`${FieldType.FinancialDerivativesFamiliarityId}_checkbox`];
        delete toAdjust[`${FieldType.CFDsFamiliarityId}_checkbox`];
        delete toAdjust[`${FieldType.WarrantsFamiliarityId}_checkbox`];
        if (toAdjust[FieldType.BirthDay]) {
            toAdjust[FieldType.BirthDate] = toAdjust[FieldType.BirthYear] + "-" + Utils.pad(toAdjust[FieldType.BirthMonth]) + "-" + Utils.pad(toAdjust[FieldType.BirthDay]);
            delete toAdjust[FieldType.BirthYear];
            delete toAdjust[FieldType.BirthMonth];
            delete toAdjust[FieldType.BirthDay];
        }
        if (Utils.isNotEmpty(toAdjust[FieldType.TaxIdentificationNumber]) && Utils.isNull(toAdjust[FieldType.IDontHaveTIN])) {
            toAdjust[FieldType.IDontHaveTIN] = false;
        }
        return Object.assign({}, complianceForm, toAdjust);
    }

    private processFormErrors = (pageType: PageType, Errors: ErrorInfo[]): void => {
        if (Utils.isArrayNotEmpty(Errors)) {
            const page: IPage = PageNavigator.page(pageType);
            const errors: TSMap<FieldType, ErrorInfo> = new TSMap();
            let atLeastOneFieldFound: boolean = false;
            if (page) {
                Errors.forEach((error: ErrorInfo) => {
                    BIUtil.Error(error.PropertyName, BIErrorType.Server, error.Message);
                    if (error.PropertyName === "TIN") {
                        Platform.bi().track(BIEventType.OBTINValidation);
                    }
                    if (error.PropertyName !== FieldType.TaxIdentificationNumber) {
                        let fieldType: FieldType = Utils.isNotNull(page.fields.filter((f: IPageField) => f.type === error.PropertyName)[0]) ? error.PropertyName as FieldType : null;
                        if (!fieldType) {
                            const fieldTypes: FieldType[] = FieldType.extraFields(error.PropertyName as FieldType);
                            if (fieldTypes) {
                                fieldType = fieldTypes.filter((fT: FieldType) => fT === error.PropertyName)[0];
                            }
                        }
                        if (fieldType) {
                            atLeastOneFieldFound = true;
                            errors.set(fieldType, error);
                        }
                    }
                });
            }
            if (atLeastOneFieldFound) {
                this._logger.debug("Found fields for errors at page: " + pageType);
                Platform.dispatch(SetFormErrors({errors}));
            } else {
                this._logger.debug("Can't find fields for errors at page: " + pageType);
                Platform.dispatch(ShowPopup({
                    popup: {
                        icon: {type: PopupIconType.ERROR},
                        message: {customValue: Errors[0].Message, trKey: TranslationKey.serverErrorGeneral},
                        showClose: true,
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        } else {
            Platform.dispatch(ShowPopup({
                popup: {
                    icon: {type: PopupIconType.ERROR},
                    message: {trKey: TranslationKey.serverErrorGeneral},
                    showClose: true,
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        }
    }

    public goToTradingPlatform = async () => {
        if (!this._goToThePlatform) {
            this._goToThePlatform = true;
            this._logger.debug("Go to trading platform.");
            if (WebUtil.inIosWebView()) {
                (window as any).webkit.messageHandlers.jsHandle.postMessage("closeWebView");
            } else if (WebUtil.inAndroidWebView()) {
                (window as any).MobileJsInterface.closeWebView();
            } else if (PlatformType === "new" || RNBridge.hasBridge()) {
                const msg: string = JSON.stringify({operation: "closeGeneralWindow"});
                RNBridge.send(msg);
                if (WebUtil.parentWindow()) {
                    WebUtil.parentWindow().postMessage(msg, "*");
                }
            } else {
                await this.doGetURLByType({
                    actionType: ActionType.Navigate,
                    urlType: GetUrlType.Profit
                });
            }
            this._goToThePlatform = false;
        } else {
            this._logger.debug("Navigation to trading platform in progress ...");
        }
    }

    public doRegisterActivity = async (payload: DoRegisterActivityPayload) => {
        const request: RegisterActivityRequest = {ActivityType: payload.activityType};
        this._activities.push(request);
    }

    private doRegisterActivityInternal = async () => {
        const request: RegisterActivityRequest = this._activities.shift();
        if (request) {
            this._logger.debug("Register activity: " + ActivityTypeInfo[request.ActivityType]);
            const answer: [HttpReject, OperationResult] = await Utils.to(XhrManager.sendToForm(request, "RegisterActivity"));
            if (answer[0]) {
                this._logger.debug("Failed register activity. Status: " + answer[0].status);
            } else {
                const {Success, Errors} = answer[1];
                if (!Success) {
                    this._logger.debug("Failed register activity with errors: " + Errors);
                }
            }
        }
    }

    public doRecordPrintClick = async () => {
        this._logger.debug("Do record print click");
        const answer: [HttpReject, UpdateComplianceFormResponse] = await Utils.to(XhrManager.sendToForm({}, "RecordPrintClick"));
        if (answer[0]) {
            this._logger.debug("Failed record print click. Status: " + answer[0].status);
        }
    }

    public doReportAgreeWithAppropriate = async (payload: DoReportAgreeWithInAppropriatePayload) => {
        this._logger.debug("Do report agreed with appropriate. Agreed: " + payload.agreed);
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const request: UpdateAppropriateRequest = {HasAgreed: payload.agreed};
        const answer: [HttpReject, UpdateAppropriateResponse] = await Utils.to(XhrManager.sendToForm(request, "UpdateAppropriate"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug("Failed report agreed with appropriate. Status: " + answer[0].status);
            XhrManager.notifyRejectReason(answer);
        } else {
            const {Success, ActionInfo, Errors} = answer[1];
            if (Success) {
                if (WebUtil.isFrame()) {
                    GlobalIntegration.instance().sendMessage(new IntegrationExecuteOperation(IntegrationOperation.CloseInappropriatePopupWindow));
                } else {
                    if (payload.agreed) {
                        this.executeFormAction(ActionInfo, () => {
                            if (!Platform.reduxState<StoreState>().kyc.kycFinished) {
                                Platform.dispatch(SetKycStarted({}));
                                this.doStarKyc();
                            }
                        });
                    } else {
                        this.goToTradingPlatform().catch(() => {});
                    }
                }
            } else {
                this.processFormErrors(null, Errors);
            }
        }
    }

    public doReportAgreeWithInAppropriate = async (payload: DoReportAgreeWithInAppropriatePayload) => {
        this._logger.debug("Do report agreed with in appropriate. Agreed: " + payload.agreed);
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const request: UpdateInAppropriateRequest = {HasAgreed: payload.agreed};
        const answer: [HttpReject, UpdateInAppropriateResponse] = await Utils.to(XhrManager.sendToForm(request, "UpdateInapproprate"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug("Failed report agreed with in appropriate. Status: " + answer[0].status);
            XhrManager.notifyRejectReason(answer);
        } else {
            const {Success, ActionInfo, Errors} = answer[1];
            if (Success) {
                if (WebUtil.isFrame()) {
                    GlobalIntegration.instance().sendMessage(new IntegrationExecuteOperation(IntegrationOperation.CloseInappropriatePopupWindow));
                } else {
                    if (payload.agreed) {
                        this.executeFormAction(ActionInfo, () => {
                            if (!Platform.reduxState<StoreState>().kyc.kycFinished) {
                                Platform.dispatch(SetKycStarted({}));
                                this.doStarKyc();
                            }
                        });
                    } else {
                        this.goToTradingPlatform().catch(() => {});
                    }
                }
            } else {
                this.processFormErrors(null, Errors);
            }
        }
    }

    public doSetSignature = async ({ShouldSetFinalSignature, ShouldSetKycSignatureOnFinalSignatureAlso}: DoSetSignaturePayload) => {
        this._logger.debug("Do set signature. Final signature: " + ShouldSetFinalSignature);
        const langCode: LangCode = LanguageUtil.languageCode();
        const request: SetSignatureRequest = {
            LanguageCode: langCode,
            ShouldSetFinalSignature,
            ShouldSetKycSignatureOnFinalSignatureAlso
        };
        if (ShouldSetFinalSignature) {
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, SetSignatureResponse] = await Utils.to(XhrManager.sendToForm(request, "SetSignature"));
            Platform.dispatch(HideLoader({}));
            if (answer[0]) {
                this._logger.debug("Failed set signature. Status: " + answer[0].status);
                Platform.dispatch(SetSignatureApproved({approved: false}));
                XhrManager.notifyRejectReason(answer);
            } else {
                const {Action, Success} = answer[1];
                Platform.dispatch(SetSignatureApproved({approved: true}));
                Platform.dispatch(DoRegisterActivity({activityType: ActivityTypeInfo.SignatureSubmitted}));
                const {complianceFlowType} = Platform.reduxState<StoreState>().kbdApp;
                if (complianceFlowType === ComplianceFlowType.CNMVOnBoarding || (!Success && Action === ActionsInfoType.ShouldGoToProductGovernanceQuestions)) {
                    this.executeFormAction(Action);
                } else {
                    BIUtil.FinishOB(BIFinishOnBoardingType.FinishOB);
                    Platform.dispatch(GoToTradingPlatform({}));
                }
            }
        } else {
            const answer: [HttpReject, SetSignatureResponse] = await Utils.to(XhrManager.sendToForm(request, "SetSignature"));
            if (answer[0]) {
                this._logger.debug("Failed set signature. Status: " + answer[0].status);
            } else {
                if (answer[1].Success) {
                    Platform.dispatch(SetHasSignature({}));
                }
                const {kycFinished} = Platform.reduxState<StoreState>().kyc;
                if (kycFinished) {
                    this.executeFormAction(answer[1].Action);
                }
            }
        }
    }
}
