import {ActionCreatorDefinition, createAction} from "redux-ts";
import {NavigateToPayload} from "platform/redux/core/CoreActions";
import {TSMap} from "typescript-map";
import {FieldType} from "kbd/enum/FieldType";
import {ErrorInfo} from "kbd/protocol/ErrorInfo";
import {ProductType} from "kbd/entry/ProductType";
import {ModalType} from "kbd/enum/ModalType";
import {ComplianceFlowType} from "kbd/enum/ComplianceFlowType";
import {Tooltip} from "kbd/core/redux/app/AppReduxState";
import {CloseMeReason} from "kbd/enum/CloseMeReason";
import Parameter from "platform/util/Parameter";
import {DynamicConfiguration} from "platform/protocol/common/DynamicConfiguration";
import {LangCode} from "platform/enum/LangCode";
import {EnvironmentConfiguration} from "kbd/protocol/response/LoginConfigurationResponse";

export const SetComplianceFlowType: string =  "SetComplianceFlow";
export const SetPlatformTypeType: string =  "SetPlatformType";
export const SetProductType: string =  "SetProduct";
export const SetTooltipType: string =  "SetTooltip";
export const SetModalType: string =  "SetModal";
export const SetCountryCodeType: string =  "SetCountryCode";
export const NextPageType: string =  "NextPage";
export const PrevPageType: string =  "PrevPage";
export const SetFormErrorsType: string =  "SetFormErrors";
export const CloseMeType: string =  "CloseMe";
export const SetBarBottomMetricsType: string =  "SetBarBottomMetrics";
export const SetT2FaVerificationEnabledType: string = "SetT2FaVerificationEnabled";
export const SetShowCallMeButtonType: string = "SetShowCallMeButton";
export const DoCallMeType: string = "DoCallMe";
export const SetCallMeSubmittedType: string = "SetCallMeSubmitted";
export const SetShowCallMeSubmittedMessageType: string = "SetShowCallMeSubmittedMessage";
export const SetDynamicConfigurationType: string = "SetDynamicConfiguration";
export const UpdatePlatformLanguageType: string = "UpdatePlatformLanguage";

export interface SetComplianceFlowPayload {

    complianceFlowType: ComplianceFlowType;
}

export interface SetPlatformTypePayload {

    platformType: string;
}

export interface SetTooltipPayload {

    tooltip: Tooltip;
}

export interface SetProductPayload {

    product: ProductType;
}

export interface SetModalPayload {

    modalType: ModalType;
    visible: boolean;
    parameters?: Parameter[];
}

export interface SetCountryCodePayload {

    countryCode: string;
}

export interface SetFormErrorsPayload {

    errors: TSMap<FieldType, ErrorInfo>;
}

export interface CloseMePayload {

    reason: CloseMeReason;
}

export interface SetBarBottomMetricsPayload {

    left?: number;
    height?: number;
}

export interface SetBooleanPayload {

    value: boolean;
}

export interface SetDynamicConfigurationPayload {

    configuration: DynamicConfiguration;
    environmentConfigurations: EnvironmentConfiguration[];
}

export interface UpdatePlatformLanguagePayload {

    langCode: LangCode;
}

export const SetComplianceFlow: ActionCreatorDefinition<SetComplianceFlowPayload, any> = createAction<SetComplianceFlowPayload>(SetComplianceFlowType);
export const SetPlatformType: ActionCreatorDefinition<SetPlatformTypePayload, any> = createAction<SetPlatformTypePayload>(SetPlatformTypeType);
export const SetProduct: ActionCreatorDefinition<SetProductPayload, any> = createAction<SetProductPayload>(SetProductType);
export const SetTooltip: ActionCreatorDefinition<SetTooltipPayload, any> = createAction<SetTooltipPayload>(SetTooltipType);
export const SetModal: ActionCreatorDefinition<SetModalPayload, any> = createAction<SetModalPayload>(SetModalType);
export const SetCountryCode: ActionCreatorDefinition<SetCountryCodePayload, any> = createAction<SetCountryCodePayload>(SetCountryCodeType);
export const NextPage: ActionCreatorDefinition<NavigateToPayload, any> = createAction<NavigateToPayload>(NextPageType);
export const PrevPage: ActionCreatorDefinition<NavigateToPayload, any> = createAction<NavigateToPayload>(PrevPageType);
export const SetFormErrors: ActionCreatorDefinition<SetFormErrorsPayload, any> = createAction<SetFormErrorsPayload>(SetFormErrorsType);
export const CloseMe: ActionCreatorDefinition<CloseMePayload, any> = createAction<CloseMePayload>(CloseMeType);
export const SetBarBottomMetrics: ActionCreatorDefinition<SetBarBottomMetricsPayload, any> = createAction<SetBarBottomMetricsPayload>(SetBarBottomMetricsType);
export const SetT2FaVerificationEnabled: ActionCreatorDefinition<SetBooleanPayload, any> = createAction<SetBooleanPayload>(SetT2FaVerificationEnabledType);
export const SetShowCallMeButton: ActionCreatorDefinition<SetBooleanPayload, any> = createAction<SetBooleanPayload>(SetShowCallMeButtonType);
export const DoCallMe: ActionCreatorDefinition<any, any> = createAction<any>(DoCallMeType);
export const SetCallMeSubmitted: ActionCreatorDefinition<any, any> = createAction<any>(SetCallMeSubmittedType);
export const SetShowCallMeSubmittedMessage: ActionCreatorDefinition<SetBooleanPayload, any> = createAction<SetBooleanPayload>(SetShowCallMeSubmittedMessageType);
export const SetDynamicConfiguration: ActionCreatorDefinition<SetDynamicConfigurationPayload, any> = createAction<SetDynamicConfigurationPayload>(SetDynamicConfigurationType);
export const UpdatePlatformLanguage: ActionCreatorDefinition<UpdatePlatformLanguagePayload, any> = createAction<UpdatePlatformLanguagePayload>(UpdatePlatformLanguageType);
