import {Engine} from "platform/engine/Engine";
import Platform from "platform/Platform";
import Utils from "platform/util/Utils";
import {IPage} from "kbd/pages/IPage";
import {PageNavigator} from "kbd/pages/PageNavigator";
import CoreEngine from "platform/engine/CoreEngine";
import {PageType} from "kbd/enum/PageType";
import {LoadLanguagePayload, SetSupportedLanguages} from "platform/redux/translation/TranslationActions";
import {Configuration} from "kbd/core/configuration/Configuration";
import {
    InitChat,
    NavigateToPayload,
    SetAppReadyPayload,
    SetBrandPropsPayload,
    SetUrls
} from "platform/redux/core/CoreActions";
import {UrlType} from "platform/enum/UrlType";
import {HttpReject} from "platform/network/http/Http";
import {AvailableLanguage, LoginConfigurationResponse, LoginDynamicConfiguration} from "kbd/protocol/response/LoginConfigurationResponse";
import {LangCode} from "platform/enum/LangCode";
import {XhrManager} from "kbd/core/engine/XhrManager";
import {AppState} from "kbd/core/state/AppState";
import {ServiceType} from "kbd/enum/ServiceType";
import {LoginConfigurationRequest} from "kbd/protocol/request/LoginConfigurationRequest";
import {ProductType} from "kbd/entry/ProductType";
import {TokenManager} from "kbd/core/util/TokenManager";
import WebUtil from "platform/util/WebUtil";
import {
    CloseMePayload,
    DoCallMe,
    SetCallMeSubmitted,
    SetComplianceFlow,
    SetDynamicConfiguration,
    SetModal,
    SetPlatformType,
    SetShowCallMeSubmittedMessage,
    UpdatePlatformLanguagePayload
} from "kbd/core/redux/app/AppReduxActions";
import {RNBridge} from "kbd/core/integration/RNBridge";
import {ModalType} from "kbd/enum/ModalType";
import {ReadyState} from "kbd/core/state/ReadyState";
import {DateTimeFormat} from "kbd/core/format/DateTimeFormat";
import {MigrationDialogType as UIMigrationDialogType} from "kbd/enum/MigrationDialogType";
import {SetLoginPlatformTypes, SetMigrationDialog} from "kbd/core/redux/board/BoardReduxActions";
import {BIUtil} from "kbd/core/util/BIUtil";
import {BIEventType} from "kbd/enum/BIEventType";
import {ThemeType} from "platform/enum/ThemeType";
import {LoginPlatformType} from "kbd/enum/LoginPlatformType";
import {ActivityTypeInfo} from "kbd/protocol/ActivityTypeInfo";
import {OperationResult} from "kbd/protocol/OperationResult";
import {RegisterActivityRequest} from "kbd/protocol/request/RegisterActivityRequest";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {DynamicConfiguration, SupportedBrowser} from "platform/protocol/common/DynamicConfiguration";
import {ConfigUtil} from "kbd/core/util/ConfigUtil";
import {UpdatePlatformLanguageRequest} from "kbd/protocol/account/UpdatePlatformLanguageRequest";
import {GetAfterLoginConfigurationResponse} from "kbd/protocol/account/GetAfterLoginConfigurationResponse";

const PlatformType: string = WebUtil.urlParam("platform");

export default class AppEngine extends Engine {

    private static _instance: AppEngine;
    private _CallMeTimeout: any;

    public static instance(): AppEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        CoreEngine.instance().setup().catch(() => {});
        AppState.instance().startSession();
        Platform.dispatch(SetPlatformType({platformType: PlatformType}));
    }

    public onChangeRoute = ({route}): void => {
        if (route?.name) {
            Platform.bi().track(BIEventType.OBOnBoardingNavigation, {
                Name: route?.name
            });
        }
    }

    public onAppReady = ({ready}: SetAppReadyPayload): void => {
        if (ready) {
            const msg: string = JSON.stringify({operation: "appReady"});
            RNBridge.send(msg);
            if (WebUtil.parentWindow()) {
                WebUtil.parentWindow().postMessage(msg, "*");
            }
            const {mixpanelApiKey, brand} = Platform.config();
            if (AppState.instance().product !== ProductType.Board) {
                XhrManager.sendToLoginSecure({}, "GetAfterLoginConfiguration").then((response: GetAfterLoginConfigurationResponse) => {
                    const appState: AppState = Platform.state(ServiceType.App);
                    appState.userId = response.UserId;
                    if (mixpanelApiKey) {
                        Platform.bi().init(mixpanelApiKey, brand);
                        Platform.bi().track(BIEventType.OBOnBoardingSessionStarted, {
                            ProductType: AppState.instance().product,
                        });
                        BIUtil.identify(response);
                    }
                });
            } else if (mixpanelApiKey) {
                Platform.bi().init(mixpanelApiKey, brand);
                Platform.bi().identify({
                    UserId: null,
                    device: BIUtil.Platform(),
                    theme: ThemeType.Light,
                    realAccount: null
                });
            }
        }
    }

    public doSetBrandProps = ({}: SetBrandPropsPayload): void => {
        ReadyState.hasLDBrandProps = true;
    }

    public onLoadLanguage = async (payload: LoadLanguagePayload) => {
        const BrandId: number = Platform.config<Configuration>().brandId;
        this._logger.debug("Fetching config for lang: " + payload.langCode);
        const request: LoginConfigurationRequest = {BrandId, LanguageCode: payload.langCode};
        const answer: [HttpReject, LoginConfigurationResponse] = await Utils.to(XhrManager.sendToLogin(request, "LoginConfiguration"));
        if (answer[0]) {
            this._logger.debug("Failed fetch configuration: " + answer[0]);
        } else {
            const response: LoginConfigurationResponse = answer[1];
            if (response?.SuccessStatus) {
                const loginConfiguration: LoginDynamicConfiguration = response.DynamicConfiguration || {};
                let SupportedBrowsers: {[key: string]: SupportedBrowser} = {};
                if (loginConfiguration.SupportedBrowsers) {
                    try {
                        SupportedBrowsers = JSON.parse(loginConfiguration.SupportedBrowsers);
                    } catch (e) {
                        this._logger.warn(`Failed parse supported browsers: ${loginConfiguration.SupportedBrowsers}`)
                    }
                }
                const dynamicConfiguration: DynamicConfiguration = {
                    SupportedBrowsers
                };
                Platform.dispatch(SetDynamicConfiguration({
                    configuration: dynamicConfiguration,
                    environmentConfigurations: response.EnvironmentConfigurations
                }));

                const {MigrationDialogType, MigrationDialogDays, MigrationDialogSignUp, LoginPlatformTypes} = response.DynamicConfiguration || {};
                this._logger.debug("Config received.");
                const appState: AppState = Platform.state(ServiceType.App);
                if (appState.product === ProductType.Board) {
                    Platform.dispatch(SetComplianceFlow({complianceFlowType: response.ComplianceFlowType}));
                    if (LoginPlatformTypes) {
                        try {
                            const loginTypes: LoginPlatformType[] = JSON.parse(LoginPlatformTypes);
                            if (Utils.isArrayNotEmpty(loginTypes)) {
                                Platform.dispatch(SetLoginPlatformTypes({loginTypes}));
                            }
                        } catch (e) {
                            this._logger.warn(`Failed parse login platform types: ${LoginPlatformTypes}`);
                        }
                    }
                    const parts: string [] = window?.location?.hostname?.split(".");
                    const domain: string = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
                    const skipMigrationDialog: boolean = domain === "thexcite.com" || domain === "trading-tech.com" || domain === "xlntrade.com";
                    if (!skipMigrationDialog) {
                        const migrationDialogType: UIMigrationDialogType = UIMigrationDialogType.deserialize(MigrationDialogType);
                        const daysLeft: number = DateTimeFormat.daysFromNow(MigrationDialogDays);
                        this._logger.debug("Migration dialog: " + migrationDialogType + " Days: " + daysLeft);
                        Platform.dispatch(SetMigrationDialog({
                            dialogType: migrationDialogType,
                            daysLeft,
                            signUpThreshold: MigrationDialogSignUp
                        }));
                        if (migrationDialogType === UIMigrationDialogType.Blocking) {
                            Platform.dispatch(SetModal({
                                modalType: ModalType.LoginNewPlatform,
                                visible: true
                            }));
                        }
                    }
                }
                if (appState.product === ProductType.Board && response.ShouldRediredtToOldLogin) {
                    Platform.environment().redirect(response.OldLoginUrl);
                } else {
                    const token: string = await TokenManager.token();
                    if (appState.product !== ProductType.Board && Utils.isEmpty(token)) {
                        this._logger.debug("Token is NULL. Redirect to login");
                        Platform.environment().redirect(ConfigUtil.UseAddressBarDomain(decodeURIComponent(response.NewLoginUrl)));
                    } else {
                        const SignUpUrl: string = decodeURIComponent(response.SignUpUrl);
                        Platform.dispatch(SetUrls({
                            urls: [
                                {type: UrlType.SignUp, url: SignUpUrl?.indexOf("qaplexop") < 0 ? ConfigUtil.UseAddressBarDomain(SignUpUrl) : SignUpUrl},
                                {type: UrlType.NewLogin, url: ConfigUtil.UseAddressBarDomain(decodeURIComponent(response.NewLoginUrl))},
                                {type: UrlType.FacebookLogin, url: decodeURIComponent(response.FacebookUrl)},
                                {type: UrlType.GoogleLogin, url: decodeURIComponent(response.GoogleUrl)}
                            ]
                        }));
                        const languages: LangCode[] = [];
                        response.AvailableLanguageConfigurations.forEach((langConfig: AvailableLanguage) => {
                            const langCode: LangCode = LangCode[langConfig.Code.toUpperCase()];
                            if (Utils.isNotNull(langCode) && langConfig.IsActive && Platform.config<Configuration>().supportedLanguages.indexOf(langCode) > -1) {
                                appState.addLanguage(langCode, langConfig);
                                languages.push(langCode);
                            }
                        });
                        Platform.dispatch(SetSupportedLanguages({languages}));
                        if (response.ChatUrl && WebUtil.isUrl(response.ChatUrl) && (appState.product === ProductType.Board || appState.product === ProductType.Kyc)) {
                            Platform.dispatch(InitChat({scriptUrl: response.ChatUrl}));
                        } else {
                            this._logger.warn("Z-chat url not configured");
                        }
                    }
                }
            } else {
                this._logger.debug("Failed fetch config: " + response?.LocalizedErrorMessage);
            }
        }
    }

    public closeMe = ({reason}: CloseMePayload): void => {
        this._logger.debug("Asking to cloe me. Reason: " + reason);
        if (WebUtil.inIosWebView()) {
            (window as any).webkit.messageHandlers.jsHandle.postMessage("closeWebView");
        } else if (WebUtil.inAndroidWebView()) {
            (window as any).MobileJsInterface.closeWebView();
        } else if (WebUtil.inRNWebView()) {
            RNBridge.send(JSON.stringify({operation: "closeGeneralWindow"}));
        } else if (WebUtil.parentWindow()) {
            WebUtil.parentWindow().postMessage(JSON.stringify({operation: "closeGeneralWindow", reason}), "*");
        }
    }

    public setNextPage = (payload: NavigateToPayload): void => {
        const nextPage: IPage = PageNavigator.next(payload.route as PageType);
        if (Utils.isNotNull(nextPage)) {
            this._logger.debug("Set next page to: " + nextPage.type);
            Platform.router().navigate(nextPage.type, payload.params);
        } else {
            this._logger.warn("Absent next page after: " + payload.route);
        }
    }

    public setPrevPage = (payload: NavigateToPayload): void => {
        const prevPage: IPage = PageNavigator.prev(payload.route as PageType);
        if (Utils.isNotNull(prevPage)) {
            this._logger.debug("Set prev page to: " + prevPage.type);
            Platform.router().navigate(prevPage.type, payload.params);
        } else {
            this._logger.warn("Absent before page related to current: " + payload.route);
        }
    }

    public doCallMeRequest = async (): Promise<void> => {
        if (this._CallMeTimeout) {
            clearTimeout(this._CallMeTimeout);
            this._CallMeTimeout = null;
        }
        const request: RegisterActivityRequest = {
            ActivityType: ActivityTypeInfo.HelpRequested
        };
        const answer: [HttpReject, OperationResult] = await Utils.to(XhrManager.sendToForm(request, "RegisterActivity"));

        const OnError = (): void => {
            Platform.dispatch(ShowPopup({
                popup: {
                    message: {
                        trKey: TranslationKey.errorGeneral
                    },
                    showClose: true,
                    icon: {type: PopupIconType.ERROR},
                    actions: [{type: PopupActionType.OK}]
                }
            }));
            this._CallMeTimeout = setTimeout(() => {
                Platform.dispatch(DoCallMe({}));
            }, 60000);
        };
        if (answer[0]) {
            OnError();
        } else {
            if (answer[1].Success) {
                Platform.dispatch(SetCallMeSubmitted({}));
                Platform.dispatch(SetShowCallMeSubmittedMessage({value: true}));
            } else {
                OnError();
            }
        }
    }

    public doUpdateLanguage = async ({langCode}: UpdatePlatformLanguagePayload): Promise<void> => {
        const appState: AppState = Platform.state(ServiceType.App);
        const request: UpdatePlatformLanguageRequest = {
            UserId: appState.userId,
            LanguageCode: langCode
        };
        await Utils.to(XhrManager.sendToWebProfitService(request, "UpdatePlatformLanguage"));
    }
}
