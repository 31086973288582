import {Engine} from "platform/engine/Engine";
import {
    SetDepositLimitsFailed,
    SetDepositLimitsQuestionnaire,
    SetDepositLimitsQuestions,
    SetDepositLimitsSubmitting,
    SubmitDepositLimitsPayload
} from "kbd/core/redux/limits/DepositLimitsReduxActions";
import Platform from "platform/Platform";
import {HideLoader, NavigateTo, SetLoader} from "platform/redux/core/CoreActions";
import {LoaderType} from "platform/enum/LoaderType";
import {HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {XhrManager} from "kbd/core/engine/XhrManager";
import {DepositLimitForm, SubmitDepositLimitRequest} from "kbd/protocol/request/SubmitDepositLimitRequest";
import {PageType} from "kbd/enum/PageType";
import {LoadLanguagePayload} from "platform/redux/translation/TranslationActions";
import {GetAnswersByQuestionKeysRequest} from "kbd/protocol/request/GetAnswersByQuestionKeysRequest";
import {QuestionKey} from "kbd/protocol/QuestionKey";
import {SubmitDepositLimitsResponse} from "kbd/protocol/response/SubmitDepositLimitsResponse";
import {TSMap} from "typescript-map";
import {FieldType} from "kbd/enum/FieldType";
import {ErrorInfo} from "kbd/protocol/ErrorInfo";
import {SetComplianceFlow, SetFormErrors} from "kbd/core/redux/app/AppReduxActions";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {GetProductGovernanceQuestionnaireResponse} from "kbd/protocol/response/GetProductGovernanceQuestionnaireResponse";
import {Route} from "platform/redux/PlatformReduxState";
import {AppState} from "kbd/core/state/AppState";
import {ServiceType} from "kbd/enum/ServiceType";
import {ProductType} from "kbd/entry/ProductType";
import {GetConfigurationRequest} from "kbd/protocol/request/GetConfigurationRequest";
import {GetConfigurationsResponse} from "kbd/protocol/response/GetConfigurationsResponse";
import {IPage} from "kbd/pages/IPage";
import {PageNavigator} from "kbd/pages/PageNavigator";
import {KycStepType} from "kbd/enum/KycStepType";
import {SetKycStepStatus} from "kbd/core/redux/kyc/KycReduxActions";
import {KycStepStatus} from "kbd/enum/KycStepStatus";
import {GetAnswersByQuestionKeysResponse} from "kbd/protocol/response/GetAnswersByQuestionKeysResponse";
import {GetComplianceFormResponse} from "kbd/protocol/response/GetComplianceFormResponse";

export default class DepositLimitsEngine extends Engine {

    private static _instance: DepositLimitsEngine;

    public static instance(): DepositLimitsEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        const appState: AppState = Platform.state(ServiceType.App);
        if (appState.product === ProductType.DepositLimits) {
            const request: GetConfigurationRequest = {LanguageCode: LangCode.EN};
            const answer: [HttpReject, GetConfigurationsResponse] = await Utils.to(XhrManager.sendToConfig(request, "GetConfigurations"));
            Platform.dispatch(SetComplianceFlow({complianceFlowType: answer[1]?.ComplianceFlowType}));
        }
    }

    public onLoadLanguage = async (payload: LoadLanguagePayload) => {
        this._logger.debug("Fetch answers for questions");
        const appState: AppState = Platform.state(ServiceType.App);
        if (appState.product === ProductType.DepositLimits) {
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            await this.fetchAnswers(payload.langCode);
            Platform.dispatch(HideLoader({}));
        }
    }

    public onChangeRoute = async (payload) => {
        const route: Route = payload.route;
        const pageType: PageType = route.name as PageType;
        const langCode: LangCode = LanguageUtil.languageCode();
        if (pageType === PageType.KycTradingProfile) {
            await this.fetchAnswers(langCode);
        }
    }

    private fetchAnswers = async (langCode: LangCode) => {
        const answer: [HttpReject, GetProductGovernanceQuestionnaireResponse] = await Utils.to(XhrManager.sendToGovernance({}, "GeProductGovernanceQuestionnaire"));
        if (answer[0]) {
            this._logger.debug("Failed fetch PG questionnaire. Status: " + answer[0].status);
        } else {
            const response: GetProductGovernanceQuestionnaireResponse = answer[1];
            Platform.dispatch(SetDepositLimitsQuestionnaire({questionnaire: response.ProductGovernanceQuestionnaire}));
            const appState: AppState = Platform.state(ServiceType.App);
            const displayWarning: boolean = !response.ShouldShowProductGovernanceQuestionnaire;
            const alreadyFilled: boolean = response.ProductGovernanceAlreadyFilled;
            if ((displayWarning || alreadyFilled) && appState.product === ProductType.DepositLimits) {
                Platform.dispatch(NavigateTo({route: displayWarning ? PageType.DepositLimitWarning : PageType.DepositLimitSuccessful}));
            } else {
                const QuestionKeys: string[] = [
                    QuestionKey.ProductGovernanceInvestingObjectiveInCFDs,
                    QuestionKey.ProductGovernanceDesiredRisk,
                    QuestionKey.ProductGovernanceYearlyDisposableIncomeCategory
                ];
                if (appState.product === ProductType.DepositLimits) {
                    const aForm: [HttpReject, GetComplianceFormResponse] = await Utils.to(XhrManager.sendToForm({}, "GetComplianceForm"));
                    if (aForm[1] && !aForm[1].Form?.InvestmentHorizonTypeId) {
                        QuestionKeys.push(QuestionKey.InvestmentHorizonType);
                    }
                }
                const request: GetAnswersByQuestionKeysRequest = {LanguageCode: langCode, QuestionKeys};
                const answer2: [HttpReject, GetAnswersByQuestionKeysResponse] = await Utils.to(XhrManager.sendToGovernance(request, "GetAnswersByQuestionKeys"));
                if (answer2[0]) {
                    this._logger.debug("Failed fetch PG questions. Status: " + answer2[0].status);
                } else {
                    this._logger.debug("Received answers for questions");
                    Platform.dispatch(SetDepositLimitsQuestions({answers: answer2[1]?.QuestionKeyWithAnswers}));
                }
            }
        }
    }

    public doSubmitDepositLimits = async (payload: SubmitDepositLimitsPayload) => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const form: DepositLimitForm = {...payload.values};
        const InvestmentHorizonTypeId: number = form[FieldType.InvestmentHorizonTypeId];
        delete form[FieldType.InvestmentHorizonTypeId];
        const langCode: LangCode = LanguageUtil.languageCode();
        const request: SubmitDepositLimitRequest = {
            LanguageCode: langCode,
            ProductGovernanceQuestionnaire: form,
            InvestmentHorizonTypeId
        };
        const answer: [HttpReject, SubmitDepositLimitsResponse] = await Utils.to(XhrManager.sendToGovernance(request, "Submit"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug("Failed submit deposit limits. Status: " + answer[0].status);
            XhrManager.notifyRejectReason(answer);
        } else {
            const {Success, Errors, IsProductGovernanceQuestionnaireMinimumPassScore} = answer[1];
            if (Success) {
                const appState: AppState = Platform.state(ServiceType.App);
                if (appState.product === ProductType.DepositLimits) {
                    if (IsProductGovernanceQuestionnaireMinimumPassScore) {
                        Platform.dispatch(NavigateTo({route: PageType.DepositLimitSuccessful}));
                    } else {
                        Platform.dispatch(SetDepositLimitsFailed({failed: true}));
                    }
                } else {
                    Platform.dispatch(SetDepositLimitsSubmitting({submitting: false}));
                    const page: IPage = PageNavigator.pageByStep(KycStepType.IdentityVerification);
                    Platform.dispatch(SetKycStepStatus({
                        stepType: KycStepType.TradingProfile,
                        status: KycStepStatus.Completed
                    }));
                    Platform.dispatch(SetKycStepStatus({
                        stepType: KycStepType.IdentityVerification,
                        status: KycStepStatus.InProgress
                    }));
                    Platform.dispatch(NavigateTo({route: page.type}));
                }
            } else {
                Platform.dispatch(SetDepositLimitsFailed({failed: false}));
                if (Utils.isArrayNotEmpty(Errors)) {
                    const errors: TSMap<FieldType, ErrorInfo> = new TSMap();
                    Errors.forEach((error: ErrorInfo) => {
                        errors.set(error.PropertyName as FieldType, error);
                    });
                    Platform.dispatch(SetFormErrors({errors}));
                } else {
                    Platform.dispatch(ShowPopup({
                        popup: {
                            icon: {type: PopupIconType.ERROR},
                            message: {trKey: TranslationKey.serverErrorGeneral},
                            showClose: true,
                            actions: [{type: PopupActionType.OK}]
                        }
                    }));
                }
            }
        }
    }
}
